// Slider

.mega-box-slider {
  position: relative;
  width: 100%;
  height: auto;
}
.slider-principal {
  visibility: hidden;
  &.--mobile {
    display: block;
  }
  &.--desktop {
    display: none;
  }
  .container {
    padding: 0px;
  }
  .slick-slide:focus {
    outline: none;
  }
  .slick-slide img {
    max-width: 100%;
  }

  .slick-dots {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1000;
    margin-top: 20px;
    li {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      text-indent: -9999px;
      background: #7f7f7f;
      width: 10px;
      height: 10px;
      cursor: pointer;
      &.slick-active {
        background: $corMain;
      }
    }
  }
}

.seta-direita,
.seta-outro-direita,
.seta-outro-esquerda,
.seta-esquerda,
.seta-slider-venda-direta-esquerda,
.seta-slider-venda-direta-direita,
.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita,
.seta-slider-simule-esquerda,
.seta-slider-simule-direita,
.seta-peca-direita,
.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-semi-direita,
.seta-adicionais-direita,
.seta-adicionais-esquerda {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: $corMain;
  color: #fff;
  z-index: 999;
  position: absolute;
  cursor: pointer;
  top: 40%;
  i {
    font-size: 40px;
    color: $corSeta;
  }
}

.seta-direita {
  right: 0;
  z-index: 1000;
}

.seta-esquerda {
  left: 0;
  z-index: 1000;
}

.seta-semi-esquerda,
.seta-semi-direita {
  top: 55%;
}

.seta-slider-venda-direta-esquerda {
  left: -50px;
  top: 53%;
}
.seta-slider-venda-direta-direita {
  right: -50px;
  top: 53%;
}

.seta-slider-de-carros-esquerda {
  left: 0px;
}
.seta-slider-de-carros-direita {
  right: 0px;
}

.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita {
  top: 53%;
}

.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-slider-simule-esquerda {
  left: 15%;
}

.seta-peca-direita,
.seta-semi-direita,
.seta-slider-simule-direita {
  right: 15%;
}

.seta-slider-simule-esquerda,
.seta-slider-simule-direita {
  top: 40%;
}

.item-slider {
  background-position: center center;
  background-size: cover;
}

.box-interessado {
  span {
    display: block;
    font-weight: 900;
    font-size: 48px;
    margin: 0;
    text-transform: uppercase;
    color: $branco;
    &.pequeno-texto-slider {
      font-size: 16px;
      margin-bottom: -10px;
    }
    &.preco-do-carro-slider {
      font-weight: 300;
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }
}

.estou-interessado {
  display: inline-block;
  background-color: #62ddbe;
  box-shadow: inset 0px 3px 0px 0px rgba(179, 255, 255, 0.75);
  @include radius(10px);
  padding: 15px;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 900;
  color: $branco;
  i {
    color: $branco;
    margin-left: 10px;
  }

  &:hover {
    color: $branco;
  }
}

.box {
  margin-top: 50px;
}

// Escolha Um Carro

.box-escolha-um-carro {
  padding-top: 65px;
  padding-bottom: 65px;
  position: relative;
}

.box-titulo-e-sub-titulo {
  margin-bottom: 30px;
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
    color: $preto;
  }

  h1 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 300;
    color: $preto;
  }
}

.box-filtro {
  span {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 20px;
    display: block;
  }
}

.filtro {
  display: inline-block;
  border: solid 1px #e5e5e5;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 70px;
  li {
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    border-right: solid 1px #7e7e7e;
    a {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 300;
      color: #636363;
    }

    &:last-child {
      border-right: none;
    }
    &.filtro-ativo {
      a {
        font-weight: 400;
      }
    }
  }
}

.slider-de-carros,
.slider-simule,
.slider-peca,
.slider-seminovos {
  height: 300px;

  .slick-dots {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1000;
    margin-top: 20px;
    li {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      text-indent: -9999px;
      background: #7f7f7f;
      width: 10px;
      height: 10px;
      cursor: pointer;
      &.slick-active {
        background: $corMain;
      }
    }
  }
}

.item-carros,
.item-interno-carros {
  vertical-align: top;
  display: inline-block;
  width: 280px;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #eeeeee;
  @include transition(0.5s);
  height: 230px;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 155px;
    text-align: center;
  }
}

.item-interno-carros {
  margin-right: 0px;
  margin-left: 0;

  .a-partir {
    margin-bottom: 5px;
  }

  .confira-oferta {
    margin-top: 10px;
  }
}

.carros-novos {
  margin-bottom: 50px;
}

.slider-simule {
  .item-carros {
    vertical-align: top;
    display: inline-block;
    width: 280px;
    border: solid 1px #eeeeee;
    @include transition(0.5s);
    height: 180px;
    overflow: hidden;
  }
}

.slider-seminovos {
  .item-carros {
    background: $branco;
  }
}

.cor-semi {
  .box-titulo-e-sub-titulo {
    h3,
    h1 {
      color: $branco;
    }
  }
}

.nome-do-carro {
  display: block;
  font-size: 18px;
  font-weight: 900;
  color: #636363;
  text-transform: uppercase;
}

.a-partir {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #636363;
  margin-bottom: 20px;
}

.confira-oferta {
  display: inline-block;
  color: $corTextoBotaoConferirOferta;
  background: $preto;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  @include transition(0.3s);
  @include radius(10px);
  &:hover {
    background: $corMain;
    color: $branco;
  }
}

.item-carros {
  &:hover {
    height: 300px;
  }
}

// Simule seu consórcio

.simule-cor {
  background: #fafafa;
}

.no-margin {
  margin: 0;
}

.escolha-outro {
  margin-top: 30px;
  display: inline-block;
  background: $corMain;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 75px;
  padding-right: 55px;
  text-transform: uppercase;
  font-weight: 900;
  color: $branco;
  i {
    margin-left: 20px;
  }
  @include transition(0.3s);

  &:hover {
    background: #bb3c2b;
    color: $branco;
  }
}

.cor-semi {
  background: $preto;
}

// Semi Novos

.procurar-semi-novo {
  margin-bottom: 65px;
  input {
    background: none;
    border: solid 1px $branco;
    height: 80px;
    color: $branco;
    border-right: none;
    &:focus {
      outline: none;
    }
  }

  .input-group-addon {
    background: none;
    border-color: $branco;
    i {
      color: $branco;
      font-size: 20px;
    }
  }
}

//Vendas Direta

.venda-direta {
  .box-titulo-e-sub-titulo {
    margin-top: 20px;
  }
}

.container-slider-venda-direta {
  position: relative;
}

.container-cards {
  display: flex;
  flex-wrap: wrap;

  .card-itens {
    margin-bottom: 10px;

    h4 {
      margin-top: 10px;
      text-transform: uppercase;
    }

    p {
      font-size: 1.3rem;
    }
  }
}

// Pós Vendas

.box-atendimento-pos {
  padding-top: 85px;
  padding-bottom: 85px;
  background-size: cover;
  background-position: center center;

  .box-titulo-e-sub-titulo {
    h3,
    h1 {
      color: $branco;
    }
  }
}

.botao-pos-venda {
  display: inline-block;
  border-bottom: solid 5px transparent;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  position: relative;
  @include radius(16px);
  @include transition(0.3s);

  img {
    vertical-align: top;
    margin-right: 25px;
  }

  .texto-do-botao {
    display: inline-block;
    margin-top: 10px;
    span {
      display: block;
      color: $branco;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 18px;
      strong {
        font-weight: 900;
      }
      margin-bottom: -8px;
    }
  }

  i {
    position: absolute;
    right: 25px;
    color: $branco;
    top: 30%;
    font-size: 30px;
  }

  &.pos-whats {
    background: #35a648;
    border-color: #40c957;

    &:hover {
      background: #40c957;
      border-color: #35a648;
    }
  }

  &.pos-pecas {
    background: #228bab;
    border-color: #36a9cc;

    &:hover {
      background: #36a9cc;
      border-color: #228bab;
    }
  }

  &.pos-revisao {
    background: #b45545;
    border-color: #ff7c66;

    &:hover {
      background: #ff7c66;
      border-color: #b45545;
    }
  }
}

// Renault Atendimento

.renault-atendimento {
  .box-titulo-e-sub-titulo {
    h3,
    h1 {
      color: $preto;
    }
  }

  .botao-pos-venda {
    background: #252525;
    border-color: #636363;
    padding-top: 10px;
    padding-bottom: 10px;

    &:hover {
      background: #636363;
      border-color: #252525;
    }
  }
}

// Itenra Carros Novos

.pequeno-texto {
  text-align: center;
  margin-bottom: 50px;
  p {
    font-size: 18px;
    font-weight: 300;
  }
}

.box-mini-mapa {
  //margin-top: 20px;
  background: #1b1b1b;
  padding-top: 15px;
  padding-bottom: 15px;

  span {
    color: $branco;
    text-transform: uppercase;
    a {
      font-weight: 700;
    }
  }
}

.nome-da-categoria {
  padding-bottom: 15px;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 20px;
  span {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    color: #636363;
  }
}

// Empresa

.sobre-pagina {
  border: solid 1px #e5e5e5;
  display: inline-block;
  padding-left: 95px;
  padding-right: 95px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  margin-top: -80px;
  background: $branco;
  width: 75%;

  .box-titulo-e-sub-titulo {
    margin-bottom: 50px;
  }

  .pequeno-texto {
    margin-bottom: 30px;
    text-align: left;
  }

  .botao-pos-venda,
  .pos-whats {
    padding-top: 10px;
    margin-top: 50px;
  }
}

.box-imagem-interna {
  height: 380px;
  img {
    width: 100%;
    max-width: 1180px;
  }
}

.box-fotos-empresa {
  background: #fafafa;
  padding-top: 55px;
  padding-bottom: 55px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  margin-top: 50px;
}

.item-galeria {
  //height: 370px;
  //background-size: contain;
  //background-repeat: no-repeat;
  //background-position: center center;
  width: 100%;
}

.item-pager-galeria {
  display: inline-block;
  margin-right: -1px;
}

.pager-galeria {
  margin-bottom: 50px;
}

.ligamos-pra-voce {
  text-align: center;
  padding-bottom: 22px;
  border-color: #252525;
  background: #636363;
  padding-top: 10px;

  img {
    position: absolute;
    left: 30px;
  }

  .texto-do-botao {
    margin-top: 15px;
    span {
      display: inline-block;
    }
  }

  &:hover {
    background: #252525;
    border-color: #636363;
  }
}

// Contato
.icone-telefone {
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: top;
  margin-top: 10px;
  i {
    font-size: 20px;
  }
}

.caixa-numero {
  display: inline-block;
  margin-left: 15px;
  span {
    display: block;
    font-size: 14px;
  }
}

.contato {
  margin-bottom: 50px;
  textarea,
  input {
    @include radius(0);
    margin-bottom: 15px;
  }
}

// Seguro

.botao-e-informacao {
  margin-bottom: 20px;
}

.botao-mais-infos {
  width: 100%;
  position: relative;
  background: #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;

  span {
    font-size: 16px;
    font-weight: 300;
  }

  .fa-plus {
    position: absolute;
    right: 15px;
    top: 18px;
    font-size: 18px;
    opacity: 1;
  }

  .fa-minus {
    position: absolute;
    right: 15px;
    top: 18px;
    font-size: 18px;
    opacity: 0;
  }
}

.box-mais-info {
  background: #eeeeee;
  padding-top: 35px;
  padding-bottom: 35px;
}

.texto-mais-info {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
}

.botoes-menores {
  display: inline-block;
  color: $branco;
  background: $bgBotaoConfiraOferta;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  @include transition(0.3s);
  @include radius(10px);

  &:hover {
    color: $corTextoBotaoConferirOferta;
    background: $corMain;
  }
}

.menor-ativo {
  background: $corMain;
  margin-left: 30px;
}

.mais-ativo {
  background: #636363;
  color: $branco;

  .fa-plus {
    opacity: 0;
  }

  .fa-minus {
    opacity: 1;
  }
}

// Consorcio

.box-escolha-carro {
  position: relative;
  /*margin-top: 100px;*/
  margin-bottom: 100px;
  .seta-slider-simule-esquerda,
  .seta-slider-simule-direita {
    top: 20%;
  }
}

.interna-consorcio {
  margin-top: 0;
  padding-top: 40px;
  background: #fafafa;
}

// Interna

.card-escolha-versao {
  padding: 50px;
  background: $branco;
  border: solid 1px #e5e5e5;
}

.nome-e-ano {
  strong {
    font-weight: 900;
  }
  font-size: 48px;
  display: block;
}

.versao-do-carro {
  strong {
    font-weight: 900;
  }
  font-size: 18px;
  display: block;
  margin-bottom: 25px;
}

.texto-legal {
  font-size: 12px;
  color: #636363;
}

.imagem-do-carro {
  text-align: center;
  margin-bottom: 30px;
  img {
    /*		width: 100%;
		max-width: 225px;*/
  }
}

.card-escolha-versao {
  .imagem-do-carro {
    img {
      width: 100%;
      display: inline-block;
      max-width: 280px;
    }
  }
}

.escolha-versao {
  height: 35px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 25px;
  font-size: 14px;
}

.box-de-preco-da-versao {
  .parcelas-consorcio {
    display: block;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    color: #636363;
  }

  .valor-consorcio {
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: #636363;
    font-size: 30px;
    font-weight: 300;
    em {
      font-style: normal;
      text-transform: none;
      font-size: 14px;
    }
  }

  .credito-consorcio {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #636363;
    margin-top: 25px;
  }

  .valor-do-credito-consorcio {
    text-align: center;
    strong {
      font-weight: 900;
    }
    font-size: 18px;
    color: #636363;
    display: block;
  }
}

.card-plano-consorcio {
  padding-top: 35px;
  padding-bottom: 12px;
  background: $branco;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;

  .tempo-do-plano,
  .contemplados {
    color: #252525;
    font-size: 14px;
    display: block;
    margin-bottom: 20px;
  }

  .valor-do-plano {
    color: #252525;
    strong {
      font-weight: 900;
    }
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.titulo-escolha-um-plano {
  display: inherit;
  margin-top: 60px;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 50px;
}

// Simulacao
.card-form-financiamento {
  padding: 50px;
  background: $branco;
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
  h1 {
    display: inherit;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 20px;
  }
}

// Modal

.custom-modal {
  display: inline-block;
  background: $corMain;
  padding-top: 45px;
  padding-left: 100px;
  padding-right: 100px;
  @include radius(20px);
  border-top-right-radius: 0;
  position: relative;
  padding-bottom: 50px;
}

#box-modal-floater {
  padding: 30px 0px 0px 0px;
  background: white;

  .tag-preencher {
    span {
      color: black;
    }
  }
}

.revisao-modal {
  max-width: 650px;
}

.tag-preencher {
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
  span {
    text-transform: uppercase;
    font-weight: 300;
    color: $branco;
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
  }

  i {
    color: $branco;
    font-size: 40px;
  }
}

.modal-contato {
  label {
    color: #fff;
  }

  input,
  textarea,
  select {
    color: #fff;
    border: none;
    border-bottom: solid 1px #c2c2c2;
    height: 40px;
    background: none;
    @include radius(0);
    box-shadow: none;

    &:focus {
      border: solid 1px $branco;
      box-shadow:
        inset 0 1px 1px rgba(255, 255, 255, 0.075),
        0 0 8px rgba(255, 255, 255, 0.6);
    }
  }
  input,
  textarea,
  select {
    &[disabled],
    &[readonly] {
      background-color: darken($corMain, 10%);
    }
  }
  select {
    padding: 6px;
  }

  select option {
    color: #1b1b1b;
  }
  textarea {
    min-height: 80px;
  }

  ::-webkit-input-placeholder {
    color: $branco;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $branco;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $branco;
  }

  :-ms-input-placeholder {
    color: $branco;
  }

  span {
    color: $branco;
    text-align: center;
    font-size: 12px;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .enviar-modal {
    background: #313131;
    color: $branco;
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;

    i {
      position: absolute;
      right: 20px;
      top: 15px;
      font-size: 30px;
      color: $branco;
    }
  }
}

.fechar-modal {
  width: 60px;
  right: 60px;
  position: absolute;
  right: 0;
  top: 0;
  background: #313131;

  text-align: center;
  line-height: 60px;
  z-index: 100;

  i {
    color: $branco;
    font-size: 30px;
    @include transition(0.3s);
  }

  &:hover {
    i {
      color: $corMain;
    }
  }
}

// Peças Internas

.box-quadro-de-pecas {
  background: #fafafa;
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

.titulo-box-filtro-pecas {
  background: #333333;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  border-bottom: solid 1px #636363;
  span {
    font-weight: 700;
    text-transform: uppercase;
    color: $branco;
    font-size: 12px;
  }
}

.pesquisa-filtro {
  background: #252525;
  border-bottom: solid 1px #636363;
  padding: 10px;

  .input-group,
  .form-group {
    width: 100%;
  }

  input {
    border: solid 1px $branco;
    height: 40px;
    background: none;
    @include radius(0);
    box-shadow: none;
    border-right: none;

    &:focus {
      border: solid 1px $branco;
      box-shadow:
        inset 0 1px 1px rgba(255, 255, 255, 0.075),
        0 0 8px rgba(255, 255, 255, 0.6);
    }
  }

  ::-webkit-input-placeholder {
    color: $branco;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $branco;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $branco;
  }

  :-ms-input-placeholder {
    color: $branco;
  }

  .input-group-addon {
    background: none;
    @include radius(0);
    border-left: none;
    border-color: $branco;
    i {
      color: $branco;
    }
  }
}

.clique-carro {
  display: block;
  background: #1b1b1b;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  position: relative;
  cursor: pointer;
  @include transition(0.3s);
  border-bottom: solid 1px #636363;
  padding-left: 10px;
  padding-right: 10px;

  span {
    @include transition(0.3s);
    text-transform: uppercase;
    font-weight: 300;
    color: $branco;
  }

  &:hover {
    background: $corMain;
  }

  i {
    color: $branco;
    font-size: 20px;
    margin-right: 10px;
    vertical-align: middle;
    opacity: 0;
    @include transition(0.3s);
  }

  &.feita-escolha {
    background: $corMain;
    border-color: $corMain;

    span {
      font-weight: 700;
    }

    i {
      opacity: 1;
    }
    .fa-times {
      position: absolute;
      right: 0;
      top: 25px;
    }
  }
}

.barra-ordenar {
  background: #e3e3e3;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 20px;

  span {
    text-transform: uppercase;
    color: #0f0f0f;
  }

  .nome-da-barra {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    color: #1b1b1b;
  }
}

.ordenar {
  display: inline-block;
  margin-left: 10px;
  li {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    border-right: solid 1px #0f0f0f;

    a {
      text-transform: uppercase;
      color: #0f0f0f;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.caixa-de-resultados {
  .item-carros {
    width: 265px;
    margin-left: 7px;
    margin-bottom: 30px;
    margin-right: 7px;
  }
}

// Interna Carro

.botoes-internos-carro {
  background: #fafafa;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 75px;
}

.box-versoes-com-mais {
  padding-bottom: 65px;
}

.box-fotos-do-carro {
  background: #fafafa;
  border-top: solid 1px #e5e5e5;
  padding-top: 30px;
  padding-bottom: 65px;
}

.interior-exterior {
  margin-bottom: 30px;
  position: relative;
}

.interior,
.exterior {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  background: #636363;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
  padding-left: 65px;
  padding-right: 65px;

  cursor: pointer;
  @include transition(0.3s);

  &:hover {
    background: $corMain;
    color: $branco;
  }

  &.ativo-in-ex {
    background: $corMain;
    color: $branco;
  }
}

.ativo-in-ex:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -15px;
  left: 12px;

  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #000;
}

.cards-adicionais {
  margin-bottom: 100px;
  position: relative;

  .slick-slide:focus {
    outline: none;
  }
}

.card-adicional {
  margin-right: 10px;
  margin-left: 10px;
  width: 290px;
  padding-top: 30px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eeeeee;
  opacity: 0.5;
  @include transition(0.5s);

  .confira-oferta {
    margin-bottom: 20px;
  }

  &:hover {
    opacity: 1;
  }

  &.adicional-ativo {
    opacity: 1;
  }
}

.nome-do-carro-adicional,
.modelo-do-carro-adicional,
.preco-do-carro-adicional {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;

  strong {
    font-weight: 900;
  }
}
.modelo-do-carro-adicional {
  font-size: 18px;
}
.preco-do-carro-adicional {
  font-size: 16px;
  margin-bottom: 20px;
}

.thumb-foto-adicional {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  img {
    display: inline-block;
    width: 100%;
    max-width: 185px;
  }
}

.adicionais-do-carro {
  display: none;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #1b1b1b;
  li {
    color: $branco;
  }
}

.ver-itens-adicional {
  display: inline-block;
  width: 100%;
  background: #f2f2f2;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 900;
  color: #929292;
  @include transition(0.3s);

  &:hover {
    color: #252525;
  }
}

.seta-adicionais-direita {
  right: -5%;
  border: solid 1px #252525;
  i {
    color: #252525;
    @include transition(0.3s);
  }
  background: none;
  @include transition(0.3s);

  &:hover {
    background: $corMain;
    border-color: $corMain;
    i {
      color: $branco;
    }
  }
}

.seta-adicionais-esquerda {
  left: -5%;
  border: solid 1px #252525;
  i {
    color: #252525;
    @include transition(0.3s);
  }
  background: none;
  @include transition(0.3s);

  &:hover {
    background: $corMain;
    border-color: $corMain;
    i {
      color: $branco;
    }
  }
}

.seta-versao-esquerda,
.seta-ultimo-esquerda,
.seta-ultimo-direita,
.seta-versao-direita {
  display: inline-block;
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  top: -50px;
  color: #828282;
  font-weight: 900;
  @include transition(0.3s);
  vertical-align: top;
  cursor: pointer;

  i {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: solid 1px #252525;
    @include transition(0.3s);
    color: #252525;
    text-align: center;
    line-height: 40px;
    margin-right: 15px;
    font-size: 24px;
  }

  &:hover {
    color: #252525;
    i {
      background: $corMain;
      color: $branco;
      border-color: $corMain;
    }
  }
}

.seta-versao-esquerda {
  left: 0;
}
.seta-versao-direita {
  right: 0;
  i {
    margin-left: 10px;
    margin-right: 0;
  }
}

.item-outro-slider {
  display: inline-block;
  width: 280px;
  background: $branco;
  height: 450px;
  margin-left: 10px;
  margin-right: 10px;
}

.thumb-imagem-outro {
  margin-bottom: 25px;
  img {
    width: 100%;
  }
}

.outro-titulo {
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  color: #636363;
  margin-bottom: 30px;
  text-align: center;
}

.outro-texto {
  padding-left: 15px;
  padding-right: 15px;
  p {
    font-weight: 300;
    font-size: 12px;
    color: #636363;
  }
}

.outro-slider {
  .slick-dots {
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1000;
    margin-top: 50px;
    li {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      text-indent: -9999px;
      background: #7f7f7f;
      width: 10px;
      height: 10px;
      cursor: pointer;
      &.slick-active {
        background: $corMain;
      }
    }
  }
}

.seta-outro-direita {
  right: 0px;
}
.seta-outro-esquerda {
  left: 0px;
}

.box-escolha-um-carro {
  .item-versao {
    .sobre-pagina {
      margin-top: 0;

      .card-adicional {
        opacity: 1;
        border: none;
      }
    }
  }
}

.box-escolha-um-carro {
  .setas-versoes {
    .seta-ultimo-direita,
    .seta-ultimo-esquerda {
      top: 40%;
      z-index: 100;
      background: $branco;
    }

    .seta-ultimo-direita {
      right: -25px;
    }
    .seta-ultimo-esquerda {
      left: -25px;
    }
  }
}

.item-pager-galeria {
  cursor: pointer;
}

.galeria-fotos {
  width: 100%;
  //height: 350px;

  @media screen and (max-width: 425px) {
    height: 135px;
  }

  picture {
    float: left;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    width: 16.666%;
    height: 33.333%;

    &:first-child {
      width: 50%;
      height: 100%;
    }
  }
}

.fotos-interior {
  .galeria-fotos {
    .slick-list {
      .slick-track {
        width: 100% !important;
        .item-galeria {
          width: 100% !important;
        }
      }
    }
  }
}
