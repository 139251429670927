

.card-component{
  min-height: 400px;
  text-align: center;
  
  .card-ctas{
      .col-sm-6, .col-md-6, 
      .col-md-12, .col-sm-12{
          padding-left: 5px;
          padding-right: 5px;
          
      }
      .button.button-smaller{
          font-size: .7em !important;
          padding: .25em .25em !important;
      }
  }

  .visible {
      font-weight: 600;
      overflow: hidden;
      height: 40px;
    }
            
    ul {
      margin-top: 0;
      height: 160px;
      text-align: left;
      list-style: none;
      animation: 6s linear 0s normal none infinite change;
    }
    
    ul li {
      line-height: 40px;
      margin: 0;
      height: 40px;
      margin-bottom: 0px;
      text-align: center;
    }

    @keyframes opacity {
      0%, 100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
    @keyframes change {
      0%, 12%, 100% {
        transform: translateY(0);
      }
      17%,29% {
        transform: translateY(-25%);
      }
      34%,46% {
        transform: translateY(-50%);
      }
      51%,63% {
        transform: translateY(-75%);
      }
      68%,80% {
        transform: translateY(-50%);
      }
      85%,97% {
        transform: translateY(-25%);
      }
    }

  .card-content-image{
      height: 170px;
      display: flex;
      align-items: flex-end;


      .card-image{
          width: 100%;
          height: auto;
      }
  }
  .button-green{
      font-size: 0.77em;
      padding: .6em 1.3em;
  }

  .card-title{
      font-family: "OpenSansExtraBoldItalic";
      font-size: 24px; 
      text-transform: uppercase; 
      color: $card-title;
      min-height: 56px;
      
  }

  .card-price{
      font-size: 122px;
      color: $card-price;
  }



  .de{
      margin: 0;
      font-size: 0.8rem;
      text-decoration: line-through;
  }

  .prepreco{
      margin: 0;
      font-size: 0.8rem;
      text-transform: uppercase;
  }



  .contagem-regressiva{
      color: #cf403e;
  }

  .containuer-preco-consorcio{
    display: flex; 
    flex-direction: row; 
    justify-content: space-around; 
    margin-top: 5px; 
    border-top: solid 1px #04a3e1; 
    border-bottom: solid 1px #04a3e1; 
    padding: 9px 0px 1px 0px;
  }

  .info{

      .condicoes{
          margin: 0;
          background: $blue;
          color: #fff;
          font-size: 0.7rem;
          padding: 5px 0px;
          font-weight: 100;
          text-transform: uppercase;
      }

      .pospreco{
          margin: 0;
          padding: 2px 0px;
          text-transform: uppercase;
          border-top: solid 1px $blue;
          border-bottom: solid 1px $blue;
          font-weight: 500;
          margin-top: 10px;
          color: $blue;
          margin-bottom: 10px;
      }

      .extras{
          text-align: left;
          font-weight: 300;
          margin-top: 0px;
          font-size: .8rem;
          margin-bottom: 0px;
          text-transform: uppercase;
          color: #333;
      }

  }

  .card-button{
      font-size: 12px;
      color: $card-button;
  }
}



@media screen and (max-width: 476px){
  .card-component{
      .card-content-image{
          margin-top: 70px;
      }
  }
}