// Horario de Funcionamento

.horario-de-funcionamento{
	padding-top: 45px;
	padding-bottom: 40px;

	.nome-da-loja{
		display: inline-block;
		margin-top: 20px;
	}
}

.logo-da-empresa{
	display: inline-block;
	margin-right: 30px;
	vertical-align: top;
	margin-top: 20px;
}

.horarios-e-mapa{
	width: 100%;
	text-align: right;
	padding-top: 35px;
	padding-bottom: 35px;
	border-left: solid 1px #e3e3e3;
}

.horario,
.mapa{
	display: inline-block;
	text-align: left;
}

.horario{ margin-top: 10px; }

.icone{
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
	i{
		color: $corMain;
		font-size: 24px;
		margin-top: 5px;
	}
}

.ver-google-maps{
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 12px;
	margin-top: 10px;
	text-transform: uppercase;
	display: inline-block;
	@include transition(0.3s);

	i{
		vertical-align: top;
		font-size: 18px;
		@include transition(0.3s);
		margin-top: -1px;
	}

	&:hover{
		i{
			margin-left: 10px;
		}
	}
}


// Footer

footer{
	padding-top: 60px;
	padding-bottom: 30px;
	background: $preto;

	.atendimento{
		li{
			padding: 0;
			display: block;
			margin-bottom: 30px;
			color: #e5e5e5;
			&:last-child{
				padding: 0;
			}
		}
	}
}

.texto-horario{
	display: inline-block;
	span{ display: block; }
}

.tag-coluna{
	color: #e5e5e5;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 18px;
	margin-bottom: 35px;
	display: inline-block;
}

.menu-footer{
	li{
		margin-top: 7px;
		margin-bottom: 7px;
		a{
			color: #e5e5e5;
			font-size: 14px;
			@include transition(0.1s);
			&:hover{
				color: $corMain;
				font-weight: 800;
			}
		}
	}
}

.ms-footer{
	li{
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		i{
			color: $preto;
			background: #636363;
			width: 25px;
			height: 25px;
			text-align: center;
			line-height: 25px;
			font-size: 15px;
			@include transition(0.3s);
			&:hover{
				background: #e5e5e5;
			}
		}
	}
}

.ass-lua{
	margin-top: 70px;
	border-top: solid 1px #636363;
	padding-top: 20px;
}

.ass-footer{
	display: block;
	color: #636363;
	font-size: 12px;
}
